var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-space-y-2"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('label',{staticClass:"required tw-text-xs"},[_vm._v(_vm._s(_vm.$t('productCategory.code')))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.category_code),expression:"model.category_code"}],staticClass:"form-control",class:{
                    'is-invalid': _vm.errors.has('category_code')
                },attrs:{"type":"text"},domProps:{"value":(_vm.model.category_code)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "category_code", $event.target.value)}}}),(_vm.errors.has('category_code'))?_c('div',{staticClass:"invalid-feedback tw-text-xs"},[_vm._v(" "+_vm._s(_vm.errors.first('category_code'))+" ")]):_vm._e()])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('label',{staticClass:"tw-text-xs"},[_vm._v(_vm._s(_vm.$t('productCategory.nameKh')))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.category_name_kh),expression:"model.category_name_kh"}],staticClass:"form-control",class:{
                    'is-invalid': _vm.errors.has('category_name_kh')
                },attrs:{"type":"text"},domProps:{"value":(_vm.model.category_name_kh)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "category_name_kh", $event.target.value)}}}),(_vm.errors.has('category_name_kh'))?_c('div',{staticClass:"invalid-feedback tw-text-xs"},[_vm._v(" "+_vm._s(_vm.errors.first('category_name_kh'))+" ")]):_vm._e()])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('label',{staticClass:"required tw-text-xs"},[_vm._v(_vm._s(_vm.$t('productCategory.nameEn')))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.category_name_en),expression:"model.category_name_en"}],staticClass:"form-control",class:{
                    'is-invalid': _vm.errors.has('category_name_en')
                },attrs:{"type":"text"},domProps:{"value":(_vm.model.category_name_en)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "category_name_en", $event.target.value)}}}),(_vm.errors.has('category_name_en'))?_c('div',{staticClass:"invalid-feedback tw-text-xs"},[_vm._v(" "+_vm._s(_vm.errors.first('category_name_en'))+" ")]):_vm._e()])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('label',{staticClass:"tw-text-xs"},[_vm._v(_vm._s(_vm.$t('productCategory.icon')))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.icon),expression:"model.icon"}],staticClass:"form-control",class:{
                    'is-invalid': _vm.errors.has('icon')
                },attrs:{"type":"text"},domProps:{"value":(_vm.model.icon)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "icon", $event.target.value)}}}),_c('small',[_c('i',[_c('a',{attrs:{"href":"https://fontawesome.com/v5/search?o=r&m=free","target":"_blank"}},[_vm._v(_vm._s(_vm.$t('productCategory.findYourIcon')))])])]),(_vm.errors.has('icon'))?_c('div',{staticClass:"invalid-feedback tw-text-xs"},[_vm._v(" "+_vm._s(_vm.errors.first('icon'))+" ")]):_vm._e()])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 tw-mt-3 tw-text-right"},[_c('ts-button',{staticClass:"tw-mr-2",on:{"click":function($event){$event.preventDefault();return (function () { return _vm.$emit('cancel'); }).apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('cancel'))+" ")]),(!_vm.isUpdate)?_c('ts-button',{attrs:{"color":"primary","waiting":_vm.waiting},on:{"click":function($event){$event.preventDefault();return _vm.onSave.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t('save')))]):_vm._e(),(_vm.isUpdate)?_c('ts-button',{attrs:{"color":"primary","waiting":_vm.waiting},on:{"click":function($event){$event.preventDefault();return _vm.onUpdate.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t('update')))]):_vm._e()],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }